import NavbarSidebar from "../../components/header/NavbarSidebar";
import Hero from "../../components/hero/Hero";
import CustomerResults from "../../components/customer_results/CustomerResults";
import HowItWorks from "../../components/how_it_works/HowItWorks";
import OurFeatures from "../../components/our_features/OurFeatures";
import Footer from "../../components/footer/Footer";
import Login from "../../components/auth/Login";


const LoginPage = () => {
  return <>
    <NavbarSidebar/>
    <Login/>
    <Footer/>
  </>
}

export default LoginPage
