import InputAdornment from "@mui/material/InputAdornment"
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid"
import {TextValidator} from "react-material-ui-form-validator"
import * as React from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import {getInputValue} from "../../utils"
import {BACKEND_URL} from "../../data/config"
import {useGlobalContext} from "../../contexts/global_context"
import {useNavigate} from "react-router-dom"
import {useEffect} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {buildDashboardURL} from "../utils/users";


const LoginEnterOtp = ({step, setStep, formValues, setFormValues}) => {
  let {sendAjax, isSendingAjax, setIsSendingAjax, userInfo, setUserInfo} = useGlobalContext()
  const navigate = useNavigate()
  const {executeRecaptcha} = useGoogleReCaptcha()

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  const handleNextButton = (step, setStep) => {
    setIsSendingAjax(true)

    executeRecaptcha('VERIFY_OTP').then(gr => {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/users/verify-otp/`,
        method: 'post',
        data: {
          ...formValues,
          gr: gr,
        },
        successMessage: '',
      }).then(r => {
        if (r.data.status_code === 200) {
          setUserInfo({
            ...r.data.data,
            isLoggedIn: true,
          })

          const urlParams = new URLSearchParams(window.location.search)
          let redirectURL = urlParams.get('redirect_uri')

          if (redirectURL) {
            navigate(redirectURL)
          } else {
            // redirect to dashboard
            navigate(buildDashboardURL(r.data.data.user_type))
          }
        }
      })
    })

  }

  return <>
    <TextValidator
      label={'کد دریافتی'}
      placeholder={'کد ارسال شده را وارد کنید...'}
      name={'otp'}
      variant={'outlined'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PhoneAndroidIcon color={'secondary'}/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      autoFocus={true}
      validators={['required']}
      errorMessages={['This field is required']}
      onChange={handleChange}
      value={formValues.otp}
      autoComplete={'off'}
    />

    <LoadingButton
      sx={{
        margin: '0 0 20px 0',
      }}
      color={'primary'}
      size={'large'}
      fullWidth={true}
      type={'submit'}
      variant="contained"
      endIcon={<KeyboardDoubleArrowLeftIcon/>}
      onClick={() => {
        handleNextButton(step, setStep)
      }}
      loading={isSendingAjax}
    >
      ورود | ثبت نام
    </LoadingButton>
  </>
}


export default LoginEnterOtp
