import Button from '@mui/material/Button'
import {baseStyles} from "./styles/styles"
import Box from "@mui/material/Box"
import {GlobalContextProvider} from "./contexts/global_context";
import {NavbarContextProvider} from "./contexts/navbar_context";


function App({children}) {
  return (
    <GlobalContextProvider>
      <NavbarContextProvider>
        <Box sx={baseStyles}>
          {children}
        </Box>
      </NavbarContextProvider>
    </GlobalContextProvider>
  )
}

export default App
