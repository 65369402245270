import {Outlet, Navigate, useNavigate} from 'react-router-dom'
import {useGlobalContext} from "../../contexts/global_context";
import {BACKEND_URL} from "../../data/config";
import {useEffect, useState} from "react";


const getRedirectUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  let redirectURL = urlParams.get('redirect_uri')
  if (redirectURL) {
    return `/login?redirect_uri=${redirectURL}`
  } else {
    let path = `${window.location.pathname}${window.location.search}`
    return `/login?redirect_uri=${encodeURIComponent(path)}`
  }
}


export const PrivateCustomerRoute = () => {
  let {sendAjax, isSendingAjax, userInfo, setUserInfo, sendingAjaxUrls} = useGlobalContext()
  let [showContent, setShowContent] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (userInfo && userInfo.isLoggedIn) {
      setShowContent(true)
    } else if (userInfo && userInfo.isLoggedIn === false) {
      navigate(getRedirectUrl(), {replace: true})
    }
  }, [userInfo]);

  return (
    showContent && <Outlet/>
  )
}

export const PrivateAdminRoute = () => {
  let {sendAjax, isSendingAjax, userInfo, setUserInfo, sendingAjaxUrls} = useGlobalContext()
  let [showContent, setShowContent] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (userInfo && userInfo.isLoggedIn && [10, 20].includes(userInfo.user_type)) {
      setShowContent(true)
    } else if (userInfo && userInfo.isLoggedIn === false) {
      navigate(getRedirectUrl(), {replace: true})
    }
  }, [userInfo]);

  return (
    showContent && <Outlet/>
  )
}
