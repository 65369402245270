import NavbarSidebar from "../../components/header/NavbarSidebar"
import Hero from "../../components/hero/Hero"
import Box from "@mui/material/Box"
import OurFeatures from "../../components/our_features/OurFeatures"
import CustomerResults from "../../components/customer_results/CustomerResults"
import HowItWorks from "../../components/how_it_works/HowItWorks"
import Footer from "../../components/footer/Footer"
import Pricing from "../../components/pricing/Pricing"


const MainPage = () => {
  return <>
    <NavbarSidebar/>
    <Hero/>
    <CustomerResults/>
    <HowItWorks/>
    <Pricing/>
    <Footer/>
  </>
}

export default MainPage
