import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import * as React from "react"
import Button from "@mui/material/Button"
import {Link} from "react-router-dom"
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined'
import SinDividerWhiteUp from "../../assets/img/sin_divider/sin-divider-white-up.svg"

const PricingBox = ({item}) => {
  const title1 = item.title.split(' ')[0]
  const title2 = item.title.split(' ').slice(1).join(' ')

  return (
    <div className={'pricing-box'}>
      {/*<div className={'pricing-box-discount'}>*/}
      {/*  {item.discount}% OFF*/}
      {/*</div>*/}

      <Paper elevation={5}>
        <div className={'pricing-box-header'}>
          <Typography variant="h5" component="div">
            {item.title}
          </Typography>

          <img
            style={{
              width: "100.4%",
              position: "relative",
              right: "0",
              // left: '-0.2%',
              bottom: "-8px",
            }}
            src={SinDividerWhiteUp} alt={''}
          />
        </div>

        <div className={'pricing-box-content'}>
          <div className={'pricing-box-content-line'}>
            <CheckCircleOutlineOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.descriptions[0]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <CheckCircleOutlineOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.descriptions[1]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <CheckCircleOutlineOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.descriptions[2]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <CheckCircleOutlineOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.descriptions[3]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <CheckCircleOutlineOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.descriptions[4]}
            </span>
          </div>

          <Typography className={'pricing-box-price'} variant="h5" component="div">
            {item.price.toLocaleString()} تومان
          </Typography>

          <Button
            component={Link}
            to={`/dashboard/checkout/${item.uid}`}
            className={'pricing-box-buy-button'}
            variant="contained"
            size="large"
            fullWidth={true}
            color={'primary'}
          >
            ثبت درخواست
          </Button>
        </div>
      </Paper>
    </div>
  )
}

export default PricingBox
