import React from 'react'
import {useContext, createContext, useState, useEffect} from "react"
import {useGlobalContext} from "./global_context";
import {BACKEND_URL} from "../data/config";
import {useNavigate, useLocation} from "react-router-dom";

let AdminContext = createContext(null)

export const AdminContextProvider = ({children}) => {
  const [isAdmin, setIsAdmin] = useState(false)
  const {sendAjax} = useGlobalContext()
  let navigate = useNavigate();
  const location = useLocation()

  // const checkIsAdmin = async () => {
  //   let ajax = sendAjax({
  //     url: BACKEND_URL + "/api/v1/users/hello/",
  //     method: "post",
  //     data: {},
  //     successMessage: "",
  //     errorMessage: "",
  //   })
  //
  //   ajax.then(r => {
  //     if (r.status === 200) {
  //       setIsAdmin(true)
  //     } else {
  //       // history.push("/admin");
  //     }
  //   })
  //
  //   return ajax
  // }

  useEffect(() => {
    // if (location.pathname.startsWith('/admin')) {
    //   checkIsAdmin()
    // }
  }, [])

  const value = {
    // isAdmin,
    // setIsAdmin,
    // checkIsAdmin,
  }

  return (
    <AdminContext.Provider value={value}>
      {children}
    </AdminContext.Provider>
  )
}

export const useAdminContext = () => {
  return useContext(AdminContext)
}
