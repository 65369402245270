import {useState} from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import {Grid, ListItemIcon, MenuItem, MenuList} from "@mui/material"
import PopupState, {bindHover, bindPopover} from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Link} from "react-router-dom"
import MuiLink from "@mui/material/Link"
import {useGlobalContext} from "../../contexts/global_context"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import {useNavbarContext} from "../../contexts/navbar_context";
import HandshakeIcon from '@mui/icons-material/Handshake';


const drawerWidth = 240
const navItems = ['تماس با ما']


const NavbarSidebar = () => {
  const {userInfo, dashboardURL} = useGlobalContext()
  let {toggleSidebar, logoutUser} = useNavbarContext()

  const renderUserButtons = () => {
    if (userInfo && userInfo.mobile) {
      return <>
        <PopupState variant="popover" popupId="demoPopover">
          {(popupState) => (
            <>
              <Button
                {...bindHover(popupState)}
                color={'secondary'}
                className={'navbar-btn'}
                variant="contained"
                endIcon={<ArrowDropDownIcon/>}
                sx={{marginRight: 1, float: 'right'}}
                onClick={() => {
                  window.location = dashboardURL
                }}
              >
                حساب کاربری
              </Button>

              <HoverPopover
                PaperProps={{
                  sx: {
                    paddingTop: '10px',
                    overflow: 'visible',
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: '50%',
                      width: 5,
                      height: 5,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      window.location = dashboardURL
                    }}
                  >
                    <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                    <ListItemText>داشبورد</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={logoutUser}
                  >
                    <ListItemIcon><LogoutIcon/></ListItemIcon>
                    <ListItemText>خروج</ListItemText>
                  </MenuItem>
                </MenuList>
              </HoverPopover>
            </>
          )}
        </PopupState>
      </>

      if (userInfo.is_staff) {
        return <>
          <Button
            color={'secondary'}
            // className={'navbar-btn'}
            onClick={() => {
              window.location.pathname = '/admin'
            }}
            variant="outlined"
          >
            مدیریت
          </Button>
        </>
      } else {

      }
    } else {
      return <>
        <Button
          color={'secondary'}
          variant="contained"
          sx={{marginRight: 1, float: 'right'}}
          component={Link}
          to={'/login'}
        >
          ورود | ثبت نام
        </Button>
      </>
    }
  }

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
      <Typography variant="h6" sx={{my: 2}}>
        مستر رژیم | علی زمانیان
      </Typography>
      <Divider/>
      <List>
        <ListItemButton component={Link} to={'/visit'} onClick={toggleSidebar(false)}>
          <ListItemIcon>
            <HandshakeIcon/>
          </ListItemIcon>
          <ListItemText primary={'ویزیت حضوری'}/>
        </ListItemButton>
      </List>
    </Box>
  )

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline/>
      <AppBar position="sticky" component="nav" color={'primary'}>
        <Toolbar
          sx={{
            maxWidth: '1300px',
            width: '100%',
            margin: 'auto',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{display: {sm: 'none'}}}
          >
            <MenuIcon/>
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: {xs: 'none', sm: 'block'},
            }}
          >
            مستر رژیم | علی زمانیان
          </Typography>

          {/* DESKTOP */}
          <Box sx={{display: {xs: 'none', sm: 'block'}}}>
            <Box sx={{marginRight: 1, display: 'inline'}}>
              {/*<PopupState variant="popover" popupId="demoPopover">*/}
              {/*  {(popupState) => (*/}
              {/*    <div style={{display: 'inline'}}>*/}
              {/*      <Button className={'navbar-btn'} {...bindHover(popupState)}>*/}
              {/*        دریافت رژیم*/}
              {/*        <ArrowDropDownIcon/>*/}
              {/*      </Button>*/}

              {/*      <HoverPopover*/}
              {/*        PaperProps={{*/}
              {/*          sx: {*/}
              {/*            paddingTop: '10px',*/}
              {/*            overflow: 'visible',*/}
              {/*            '&:before': {*/}
              {/*              content: '""',*/}
              {/*              display: 'block',*/}
              {/*              position: 'absolute',*/}
              {/*              top: 0,*/}
              {/*              right: '50%',*/}
              {/*              width: 5,*/}
              {/*              height: 5,*/}
              {/*              bgcolor: 'background.paper',*/}
              {/*              transform: 'translateY(-50%) rotate(45deg)',*/}
              {/*              zIndex: 0,*/}
              {/*            },*/}
              {/*          },*/}
              {/*        }}*/}
              {/*        {...bindPopover(popupState)}*/}
              {/*        anchorOrigin={{*/}
              {/*          vertical: 'bottom',*/}
              {/*          horizontal: 'center',*/}
              {/*        }}*/}
              {/*        transformOrigin={{*/}
              {/*          vertical: 'top',*/}
              {/*          horizontal: 'center',*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <NavbarSubmenu subMenuItems={[*/}
              {/*          {uid: '3344344', title: 'رژیم افزایش وزن'},*/}
              {/*          {uid: '2332', title: 'رژیم کاهش وزن'},*/}
              {/*          {uid: '334121234344', title: 'رژیم نثبیت وزن'},*/}
              {/*          {uid: '5455665', title: 'رژیم ورزشی'},*/}
              {/*        ]}/>*/}
              {/*      </HoverPopover>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</PopupState>*/}

              {navItems.map((item) => (
                <Button key={item} className={'navbar-btn'}>
                  {item}
                </Button>
              ))}
            </Box>

            {renderUserButtons()}
          </Box>

          {/* MOBILE */}
          <Box sx={{width: '100%', display: {xs: 'block', sm: 'none'}}}>
            <Typography variant="h6" component="div" sx={{display: 'inline'}}>
              مستر رژیم!
            </Typography>

            {
              userInfo && userInfo.isLoggedIn ?
                <Button
                  color={'secondary'}
                  variant="contained"
                  sx={{marginRight: 1, float: 'right'}}
                  component={Link}
                  to={dashboardURL}
                >
                  حساب کاربری
                </Button>
                :
                <Button
                  color={'secondary'}
                  variant="contained"
                  sx={{marginRight: 1, float: 'right'}}
                  component={Link}
                  to={'/login'}
                >
                  ورود | ثبت نام
                </Button>
            }

          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  )
}

const NavbarSubmenu = ({subMenuItems}) => {
  return (
    <MenuList>
      {subMenuItems.map((item, index) => {
        return (
          <MenuItem
            key={item.uid}
          >
            {/*<ListItemIcon>{getIconByCategoryTitle(item.title)}</ListItemIcon>*/}
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        )
      })}
    </MenuList>
  )
}

export default NavbarSidebar
