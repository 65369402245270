import React from "react"
import {Card, Button, Grid, Avatar, withStyles, CircularProgress} from "@mui/material"
import {pink} from "@mui/material/colors";
import LinearProgress from '@mui/material/LinearProgress'
import Box from "@mui/material/Box";


const styles = theme => ({
  root: {
    width: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  input: {
    display: "none"
  },
  img: {
    width: 200,
    height: 256,
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  }
})

class ImageUploadCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mainState: "initial", // initial
      imageUploaded: 0,
      selectedFile: this.props.selectedFile,
      title: this.props.title,
      inputID: this.props.inputID,
      inputName: this.props.inputName,
      onChange: this.props.onChange,
      isUploaded: this.props.isUploaded,
    }
  }

  // state = {
  //   mainState: "initial", // initial
  //   imageUploaded: 0,
  //   selectedFile: "avatar.jpg"
  // }

  handleUploadClick = event => {
    let file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = function (e) {
      this.setState({
        selectedFile: [reader.result]
      })
    }.bind(this)

    this.setState({
      mainState: "uploaded",
      selectedFile: event.target.files[0],
      imageUploaded: 1
    })

    this.props.onChange(event)
  }

  renderInitialState() {
    const {classes, theme} = this.props
    const {value} = this.state

    return (
      <label htmlFor={this.props.inputID}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <img
              width="100%"
              src={this.state.selectedFile}
            />
          </Grid>

          <Button variant="text" component="span" color={'secondary'} sx={{marginBottom: 2}}>
            {this.props.title} را انتخاب کنید
            <input
              style={{
                display: "none",
              }}
              accept="image/*"
              type="file"
              onChange={this.handleUploadClick}
              id={this.props.inputID}
              name={this.props.inputName}
            />
          </Button>
        </Grid>
      </label>
    )
  }

  renderUploadedState() {
    const {classes, theme} = this.props

    return (
      <label htmlFor={this.props.inputID}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <img
              width="100%"
              src={this.state.selectedFile}
            />
          </Grid>

          <Button variant="text" component="span" sx={{marginBottom: 2}}>
            {this.props.isUploaded ?
              <span style={{marginTop: 1}}>{this.props.title}</span> :
              <Box sx={{display: 'flex', marginTop: 1}}>
                <CircularProgress color={'secondary'} size={30}/>
              </Box>
            }

            <input
              style={{
                display: "none"
              }}
              accept="image/*"
              type="file"
              onChange={this.handleUploadClick}
              id={this.props.inputID}
              name={this.props.inputName}
            />
          </Button>
        </Grid>
      </label>
    )
  }

  render() {
    return (
      <div>
        <Card className={this.props.cardName}>
          {(this.state.mainState === "initial" && this.renderInitialState()) ||
            (this.state.mainState === "uploaded" && this.renderUploadedState())}
        </Card>
      </div>
    )
  }
}

export default ImageUploadCard
