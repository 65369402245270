import NavbarSidebar from "../../components/header/NavbarSidebar"
import Footer from "../../components/footer/Footer"
import CustomerCheckoutDiet from "../../components/customer_panel/checkout/CustomerCheckoutDiet";

const CustomerCheckoutDietPage = () => {
  return <>
    <NavbarSidebar/>
    <CustomerCheckoutDiet/>
    <Footer/>
  </>
}

export default CustomerCheckoutDietPage
