import {useNavigate, useParams} from 'react-router-dom'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React, {useEffect, useRef, useState} from "react"
import {getFieldErrorParams, getInputValue} from "../../../utils"
import AdminContainer from "../../../components/admin/AdminContainer"
import {Checkbox, FormControlLabel, FormHelperText, Grid} from "@mui/material"
import {useGlobalContext} from "../../../contexts/global_context"
import {BACKEND_URL} from "../../../data/config"
import MenuItem from "@mui/material/MenuItem";
import {Helmet} from "react-helmet";
import LoadingButton from "@mui/lab/LoadingButton";

const UserCreateUpdateAdminPage = () => {
  const navigate = useNavigate()
  let {uid} = useParams()
  let form = useRef()
  let {sendAjax, isSendingAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})

  useEffect(() => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/users/admin/users/${uid}/`,
        method: 'get',
        data: {},
      }).then((r) => {
        if (r.data.status_code === 200) {
          let user = r.data.data
          setFormValues({
            first_name: user.first_name,
            last_name: user.last_name,
            mobile: user.mobile,
            user_type: user.user_type,
            is_active: user.is_active,
          })
        }
      })
    }
  }, [])

  let [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    mobile: '',
    user_type: '',
    is_active: true,
  })

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      navigate("/admin/users")
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/users/admin/users/${uid}/`,
        method: 'put',
        data: formValues,
      }).then(
        afterSubmit,
      )
    } else {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/users/admin/users/`,
        method: 'post',
        data: formValues,
      }).then(
        afterSubmit,
      )
    }
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({})
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>مدیریت کاربران - مستر رژیم!</title>
      </Helmet>

      <Box
        component={ValidatorForm}
        ref={form}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              select
              label="نوع کاربر"
              name={'user_type'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['نوع کاربر را انتخاب کنید']}
              onChange={handleChange}
              value={formValues.user_type}
              {...getFieldErrorParams(fieldErrors, 'user_type')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>ادمین</MenuItem>
              <MenuItem value={20}>کارشناس</MenuItem>
              <MenuItem value={30}>کلاینت</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'موبایل'}
              placeholder={'0912XXXXXXX'}
              name={'mobile'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['شماره موبایل کاربر را وارد کنید']}
              onChange={handleChange}
              value={formValues.mobile}
              {...getFieldErrorParams(fieldErrors, 'mobile')}
              InputLabelProps={{
                maxLength: 11,
                type: 'number',
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'نام'}
              placeholder={'نام'}
              name={'first_name'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={formValues.first_name}
              {...getFieldErrorParams(fieldErrors, 'first_name')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'نام خانوادگی'}
              placeholder={'نام خانوادگی'}
              name={'last_name'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={formValues.last_name}
              {...getFieldErrorParams(fieldErrors, 'last_name')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              label={'فعال'}
              control={
                <Checkbox
                  checked={formValues.is_active}
                  onChange={handleChange}
                  name={'is_active'}
                />
              }
              sx={{
                marginTop: '-10px',
                // marginBottom: '-10px',
              }}
            />

            <FormHelperText
              // sx={{
              //   marginBottom: '30px',
              // }}
            >
              کاربر امکان ورود به پنل را داشته باشد؟
            </FormHelperText>
          </Grid>
        </Grid>

        <LoadingButton
          color={'primary'}
          type={'submit'}
          variant="contained"
          loading={isSendingAjax}
          sx={{
            margin: '20px 0 20px 0',
          }}
        >
          ذخیره
        </LoadingButton>
      </Box>
    </AdminContainer>
  )
}

export default UserCreateUpdateAdminPage
