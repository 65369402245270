import Pricing from "../../pricing/Pricing";
import CustomerDietList from "./CustomerDietList";


const CustomerDashboard = () => {
  return <>
    <CustomerDietList/>
    <Pricing/>
  </>
}

export default CustomerDashboard
