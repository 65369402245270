import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import FooterCol from "./FooterCol";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import {Link} from "react-router-dom";
import * as React from "react";


const Footer = () => {
  return <>
    <footer className={'footer'}>
      <div className={'footer-container'}>
        <Grid container>
          <Grid item xs={12} sm={6} md={8}>
            <FooterCol
              title={"ارتباط با ما"}
              items={[
                {
                  'uid': '1',
                  'title': 'ایمیل: support@mrregym.com',
                  'slug': 'mailto:support@mrregym.com',
                },
                {
                  'uid': '2',
                  'title': 'تلگرام: mrregym_team@',
                  'slug': 'https://t.me/mrregym_team',
                },
                {
                  'uid': '3',
                  'title': 'اینستاگرام: mrregym@',
                  'slug': 'https://instagram.com/mrregym',
                },
                {
                  'uid': '4',
                  'title': 'یوتیوب: mrregym@',
                  'slug': 'https://www.youtube.com/@mrregym',
                },
                {
                  'uid': '5',
                  'title': 'آدرس کلینیک: تهران، چهار راه کامرانیه، نبش اندرزگو، برج دریای نور، طبقه ۶، واحد ۶۰۲',
                  'slug': '',
                },
              ]}
            />

            <div className={'footer-col'}>
              <Typography
                sx={{
                  margin: '10px 0 10px 0',
                }}
                variant="body2"
                component="div"
              >
                <MuiLink
                  sx={{
                    textDecoration: 'none',
                  }}
                  component={Link}
                  to={'mailto:dzhmamirwork@gmail.com'}
                  color={'text.primary'}
                >
                  طراحی و توسعه وبسایت: <br/>
                  امیر دژکام - dzhmamirwork@gmail.com
                </MuiLink>
              </Typography>
            </div>
          </Grid>

          {/*<Grid item xs={12} sm={6} md={4}>*/}
          {/*  */}
          {/*</Grid>*/}


          <Grid item xs={12} sm={12} md={12}>
            <Divider variant="middle"/>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={'footer-col'}>
              <Typography variant="h6" component="div">
                مستر رژیم
              </Typography>

              <Typography variant="body1" component="p" color={'text.light'}>
                support@mrregym.com
              </Typography>

              <Typography variant="body1" component="p" color={'text.light'}>
                تمامی حقوق متعلق به علی زمانیان میباشد.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={'footer-col'}>
              {/*<img className={'payment-card-img'} src={VisaCardLogo} alt={'Visa Card'}/>*/}
              {/*<img className={'payment-card-img'} src={MasterCardLogo} alt={'Mastercard'}/>*/}
              {/*<img className={'payment-card-img'} src={DiscoverCardLogo} alt={'Discover Card'}/>*/}
              {/*<img className={'payment-card-img'} src={AmExLogo} alt={'American Express'}/>*/}
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  </>
}

export default Footer
