import {Divider} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import {Link, useNavigate} from "react-router-dom"
import MuiLink from '@mui/material/Link'
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SinDividerGreyUp from "../../assets/img/sin_divider/sin-divider-grey-up.svg"
import {useEffect, useRef, useState} from "react"
import {grey} from "@mui/material/colors"
import LockOpenIcon from '@mui/icons-material/LockOpen'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import LoadingButton from "@mui/lab/LoadingButton"
import LoginForm from "./LoginEnterMobile";
import LoginMobileForm from "./LoginEnterMobile";
import LoginEnterOtp from "./LoginEnterOtp";
import {useGlobalContext} from "../../contexts/global_context"
import {BACKEND_URL} from "../../data/config";


const Login = () => {
  let form = useRef()
  let [step, setStep] = useState('ENTER_MOBILE')

  // const {executeRecaptcha} = useGoogleReCaptcha()
  const navigate = useNavigate()
  // let {sendAjax, isSendingAjax, setIsSendingAjax, userInfo, setUserInfo} = useGlobalContext()
  let [formValues, setFormValues] = useState({
    mobile: "",
    otp: "",
  })



  // useEffect(() => {
  //   // check is logged in
  //   if (userInfo && userInfo.email) {
  //     if (userInfo.is_staff) {
  //       window.location.pathname = `/admin/orders/`
  //     } else {
  //       window.location.pathname = `/dashboard/`
  //     }
  //   }
  // }, [userInfo])
  //
  // const handleSubmit = () => {
  //   if (!executeRecaptcha) {
  //     return
  //   }
  //
  //   setIsSendingAjax(true)
  //
  //   executeRecaptcha('LOGIN').then(gr => {
  //     sendAjax({
  //       url: BACKEND_URL + "/api/v1/users/csrf/",
  //       method: "post",
  //       data: {},
  //       successMessage: "",
  //     }).then(() => {
  //         setIsSendingAjax(true)
  //
  //         setTimeout(() => {
  //           sendAjax({
  //             url: BACKEND_URL + "/api/v1/users/login/",
  //             method: "post",
  //             data: {
  //               ...formValues,
  //               gr: gr,
  //             },
  //             successMessage: "",
  //           }).then((r) => {
  //             if (r && r.data.status_code === 200) {
  //               setUserInfo(r.data.data)
  //             }
  //           })
  //         }, 100)
  //       }
  //     )
  //   })
  // }

  return <>
    <div className={'login'}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={'login-container'} elevation={5}>
            <div className={'login-header'}>
              <Typography variant="h4" component="h1" gutterBottom>
                ورود به سایت
              </Typography>

              <Typography color={grey[600]} variant="body1" gutterBottom>
                به وبسایت مستر رژیم (علی زمانیان) خوش اومدید! برای دریافت رژیم
                و برنامه تمرینی اختصاصی، اول شماره موبایلتون رو وارد کنید تا کد ورود به سایت براتون ارسال بشه 🙏
              </Typography>
            </div>

            <Divider/>

            <div className={'login-content'}>
              <Box
                component={ValidatorForm}
                ref={form}
                onSubmit={() => {}}
                debounceTime={1500}
                sx={{
                  '& .MuiTextField-root': {marginBottom: '30px'},
                }}
              >
                {renderLoginForm({step, setStep, formValues, setFormValues})}

              </Box>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <img className={'sin-divider-down'} src={SinDividerGreyUp} alt={''}/>
    </div>
  </>
}





const renderLoginForm = ({step, setStep, formValues, setFormValues}) => {
  if (step === 'ENTER_MOBILE') {
    return <LoginMobileForm {...{step, setStep, formValues, setFormValues}}/>
  } else if (step === 'ENTER_OTP_CODE') {
    return <LoginEnterOtp {...{step, setStep, formValues, setFormValues}}/>
  }
}


export default Login
