import NavbarSidebar from "../../components/header/NavbarSidebar"
import Footer from "../../components/footer/Footer"
import CustomerOrderDiet from "../../components/customer_panel/order_diet/CustomerOrderDiet"

const CustomerOrderDietPage = () => {
  return <>
    <NavbarSidebar/>
    <CustomerOrderDiet/>
    <Footer/>
  </>
}

export default CustomerOrderDietPage
