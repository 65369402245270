import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button, Card, CardActions, CardContent,
  DialogContent, Divider,
  IconButton,
  useMediaQuery
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "@emotion/react";
import {Link, useNavigate} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close'
import {useGlobalContext} from "../../../contexts/global_context";
import Box from "@mui/material/Box";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import SaveIcon from '@mui/icons-material/Save';
import {ValidatorForm} from "react-material-ui-form-validator";
import {BACKEND_URL} from "../../../data/config";
import Typography from "@mui/material/Typography";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {Link as MuiLink} from "@mui/material"


const CustomerDietDetailsDialog = ({fetchData, dietUid}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const {executeRecaptcha} = useGoogleReCaptcha()
  let form = useRef()
  let {isSendingAjax, showAlert, sendAjax, setIsSendingAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})
  const [fingerprint, setFingerprint] = useState('')
  const [showNewCardForm, setShowNewCardForm] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [diet, setDiet] = useState({})

  let formInitValues = {
    card_number: '',
    expire_date: '',
    cvv: '',
    first_name: '',
    last_name: '',
    billing_address: '',
    billing_zipcode: '',
    billing_country: '',
    fingerprint: '',
    is_primary: false,
    payment_method: '',
  }

  let [formValues, setFormValues] = useState({...formInitValues})

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      handleClose()
      fetchData()

    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    setIsSendingAjax(true)

    executeRecaptcha('CHECKOUT').then(gr => {
      let expireDate = formValues.expire_date.replaceAll(' ', '')
      expireDate = `${expireDate.split('/')[0]}/20${expireDate.split('/')[1]}`

      let newFormValues = {
        ...formValues,
        expire_date: expireDate,
        card_number: formValues.card_number.split(" ").join(""),
        fingerprint: fingerprint,
        usage_type: formValues.is_primary ? 10 : 20,
        gr: gr,
      }

      if (dietUid) {
        // update
        sendAjax({
          url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/${dietUid}/`,
          method: "put",
          data: newFormValues,
        }).then((r) => {
          afterSubmit(r)
        })

      } else {
        // create
        sendAjax({
          url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/`,
          method: "post",
          data: newFormValues,
        }).then((r) => {
          afterSubmit(r)
        })
      }
    })
  }

  const handleClose = () => {
    setFieldErrors({})
    setFormValues({...formInitValues})
    navigate('/dashboard', {replace: true})
  }

  useEffect(() => {
    if (dietUid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/diet/customer/manual-diet/details/${dietUid}/`,
        method: "get",
        data: {},
      }).then((r) => {
        if (r.data.status_code === 200) {
          setDiet(r.data.data)
        }
      })
    }
  }, [dietUid])

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={Boolean(dietUid)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          جزئیات رژیم و برنامه تمرینی
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            component={ValidatorForm}
            onSubmit={handleSubmit}
            ref={form}
            sx={{
              // ...baseStyles,
              paddingTop: 1,
              '& .MuiTextField-root': {marginBottom: '30px'},
            }}
          >
            <Card>
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  خبر خوب!
                </Typography>

                <Typography sx={{mb: 1.5}} color="text.secondary" gutterBottom>
                  رژیم یا برنامه تمرینی شما آماده شده است. لطفا از طریق لینک های زیر فایلهای مربوطه را دانلود کنید.
                </Typography>

                <Typography variant="body2" gutterBottom>
                  <Grid container spacing={2}>
                    {diet && diet.diet_files && diet.diet_files.map((file, i) => {
                      return <Grid item key={file}>
                        <Button
                          variant="outlined"
                          color={'secondary'}
                          startIcon={<AttachFileIcon/>}
                          component={MuiLink}
                          href={`${BACKEND_URL}/api/v1/diet/customer/manual-diet/download/${file}`}
                        >
                          دانلود فایل {i + 1}
                        </Button>
                      </Grid>
                    })}

                  </Grid>
                </Typography>

                <Divider sx={{margin: '20px 0 20px 0'}}/>

                <Typography variant="body2">
                  جهت دریافت مشاوره و راهنمایی در طول دوره،‌ میتوانید به آیدی تلگرام
                  <a href="https://t.me/mrregym_team"> mrregym_team@ </a>
                  پیام بدین.
                </Typography>
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>

          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CustomerDietDetailsDialog
