import AdminContainer from "../../../components/admin/AdminContainer"
import React, {useEffect, useState} from "react"
import AdminTable from "../../../components/admin/AdminTable"
import {BACKEND_URL} from "../../../data/config"
import {useGlobalContext} from "../../../contexts/global_context"
import moment from "moment"
import {Link, useNavigate, useParams} from "react-router-dom"
import OrderDetailsDialog from "../../../components/admin/manual_diet/ManualDietDetailsDialog"
import {Grid, Link as MuiLink, TextField} from "@mui/material"
import {getInputValue} from "../../../utils"
import InputAdornment from "@mui/material/InputAdornment"
import SearchIcon from '@mui/icons-material/Search'
import Button from "@mui/material/Button"
import {Helmet} from "react-helmet"
import ManualDietDetailsDialog from "../../../components/admin/manual_diet/ManualDietDetailsDialog";
import ManualDietListFilters from "../../../components/admin/manual_diet/ManualDietListFilters";

const ManualDietListAdminPage = () => {
  let {uid} = useParams()
  const {sendAjax, showAlert} = useGlobalContext()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  const [invoice, setInvoice] = useState('')
  const navigate = useNavigate()
  let items = []
  let numTotal = 0

  const [filters, setFilters] = useState({
    plan_type: [],
    status: [20, 30, 40],
    first_name: '',
    last_name: '',
  })

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    fetchData()
  }

  const handleOpenByInvoice = (event) => {
    event.preventDefault()

    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/list/`,
      method: "get",
      data: {
        invoice_number: invoice,
      },
    }).then((r) => {
      if (r.data.data.results.length) {
        navigate(`/admin/orders/details/${r.data.data.results[0]['uid']}`)
        setInvoice('')
      } else {
        showAlert('Not found.', 'error')
      }
    })
  }

  const handleChangeFilterInput = (event) => {
    setFilters({...filters, [event.target.name]: getInputValue(event.target)})
  }

  const handleChange = (event) => {
    setFilters({...filters, [event.target.name]: getInputValue(event.target)})
  }

  const handleComboBoxChange = (name) => (event, value) => {
    setFilters({...filters, [name]: value})
  }

  const fetchData = () => {
    const query = new URLSearchParams(filters)

    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/admin/manual-diet/list/?${query.toString()}`,
      method: "get",
      data: {page: page + 1},
    }).then((r) => {
      setData(r.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      let createdAt = moment(item.created_at)
      let detailsURL = `/admin/manual-diets/details/${item.uid}`

      items.push({
        'uid': item.uid,
        "#": index + 1,
        "شناسه یکتا": <MuiLink component={Link} to={detailsURL}>{item.invoice_number}</MuiLink>,
        "وضعیت": item.status_display,
        'نام': item.first_name,
        'نام خانوادگی': item.last_name,
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return <>
    <Helmet>
      <title>سفارشات رژیم و برنامه تمرینی - مستر رژیم!</title>
    </Helmet>

    {<ManualDietDetailsDialog uid={uid}/>}

    <AdminContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <ManualDietListFilters {...{fetchData, handleChange, filters, handleComboBoxChange}}/>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <AdminTable
            items={items}
            numTotal={numTotal}
            page={page}
            setPage={setPage}
          />
        </Grid>
      </Grid>
    </AdminContainer>
  </>
}

export default ManualDietListAdminPage
