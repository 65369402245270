import NavbarSidebar from "../../components/header/NavbarSidebar"
import Footer from "../../components/footer/Footer"
import CustomerCheckoutDiet from "../../components/customer_panel/checkout/CustomerCheckoutDiet";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {BACKEND_URL} from "../../data/config";
import {useGlobalContext} from "../../contexts/global_context";

const CustomerZarinpalCallbackPage = () => {
  let {zarinReqUid} = useParams()
  let {sendAjax, isSendingAjax, showAlert} = useGlobalContext()
  const urlParams = new URLSearchParams(window.location.search)
  const navigate = useNavigate()

  useEffect(() => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/payments/zarinpal/callback/${zarinReqUid}/`,
      method: 'post',
      data: {
        authority: urlParams.get('Authority'),
        status: urlParams.get('Status'),
      },
      successMessage: '',
      errorMessage: '',
    }).then(r => {
      if (r.data.status_code === 200 && r.data.data.status === 20) {
        navigate(`/dashboard/order-diet/${r.data.data.manual_diet_uid}`)
        showAlert(r.data.data.message, 'success')
      } else {
        sendAjax({
          url: `${BACKEND_URL}/api/v1/payments/zarinpal/callback/${zarinReqUid}/`,
          method: 'post',
          data: {
            authority: urlParams.get('Authority'),
            status: urlParams.get('Status'),
          },
          successMessage: '',
          errorMessage: '',
        }).then(r => {
          if (r.data.status_code === 200 && r.data.data.status === 20) {
            navigate(`/dashboard/order-diet/${r.data.data.manual_diet_uid}`)
            showAlert(r.data.data.message, 'success')
          } else {
            navigate(`/dashboard/checkout/${r.data.data.plan_uid}`)
            showAlert(r.data.data.message, 'error')
          }
        })
      }
    })
  }, []);

  return <>

  </>
}

export default CustomerZarinpalCallbackPage
