import AdminContainer from "../../../components/admin/AdminContainer"
import React, {useEffect, useState} from "react"
import AdminTable from "../../../components/admin/AdminTable"
import {BACKEND_URL} from "../../../data/config"
import {Button, IconButton} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import {Link} from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import {useGlobalContext} from "../../../contexts/global_context"
import {Helmet} from "react-helmet";


const UserListAdminPage = () => {
  let {sendAjax} = useGlobalContext()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  const [deletingItemUid, setDeletingItemUid] = useState("")
  let items = []
  let numTotal = 0

  const fetchData = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/users/admin/users/`,
      method: "get",
      data: {page: page + 1},
    }).then((r) => {
      setData(r.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      items.push({
        "uid": item.uid,
        "#": index + 1,
        "نوع کاربر": item.user_type_display,
        "موبایل": item.mobile,
        "نام": item.first_name,
        "نام خانوادگی": item.last_name,
        "وضعیت": item.is_active ? 'فعال' : 'غیرفعال',
        "عملیات": (
          <>
            <IconButton
              component={Link}
              to={`/admin/users/update/${item.uid}`}
            >
              <EditIcon/>
            </IconButton>
          </>
        ),
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>مدیریت کاربران - مستر رژیم!</title>
      </Helmet>

      <Button
        component={Link}
        to={"/admin/users/create"}
        variant="outlined"
        startIcon={<AddIcon/>}
        sx={{marginBottom: "20px"}}
      >
        کاربر جدید
      </Button>

      <AdminTable
        items={items}
        numTotal={numTotal}
        page={page}
        setPage={setPage}
      />
    </AdminContainer>
  )
}

export default UserListAdminPage
