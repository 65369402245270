import {useGlobalContext} from "../../../contexts/global_context"
import {useEffect, useRef, useState} from "react"
import {useParams} from "react-router-dom"
import {BACKEND_URL} from "../../../data/config"
import {Divider, Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import {Swiper, SwiperSlide} from "swiper/react"
import {Navigation, Pagination} from "swiper/modules"
import PricingBox from "../../pricing/PricingBox"
import SinDividerGreyUp from "../../../assets/img/sin_divider/sin-divider-grey-up.svg"
import * as React from "react"
import Button from "@mui/material/Button"
import CustomerDietBox from "./CustomerDietBox";
import CustomerDietDetailsDialog from "./CustomerDietDetailsDialog";
import {scroller} from "react-scroll";


const CustomerDietList = () => {
  let {sendAjax} = useGlobalContext()
  let [plans, setPlans] = useState([])
  let {dietUid} = useParams()
  let sliderRef = useRef()
  let [tab, setTab] = useState('')
  let [diets, setDiets] = useState([])

  const scrollToPricing = () => {
    scroller.scrollTo("pricing-scroll-element", {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuint",
      offset: -200,
    });
  }

  useEffect(() => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/diet/customer/manual-diets/`,
      method: 'get',
      data: {
        page_size: 1000,
      },
    }).then(r => {
      if (r.data.status_code) {
        setDiets(r.data.data.results)
        // try {
        //   sliderRef.current.swiper.slideToLoop(0)
        // } catch (e) {
        // }
      }
    })

    if (true) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/diet/plans/`,
        method: 'get',
        data: {
          page_size: 1000,
        },
      }).then(r => {
        if (r.data.status_code) {
          setPlans(r.data.data.results)
          // try {
          //   sliderRef.current.swiper.slideToLoop(0)
          // } catch (e) {
          // }
        }
      })
    }
  }, [])

  return (
    <div className={'pricing'}>
      <div className={'pricing-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={'pricing-head'}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant={'h4'} component={'h1'}>
                  رژیم‌ها و برنامه‌های من
                </Typography>

                <br/>
                <Divider/>
                <br/>

                <Grid container spacing={2}>
                  {diets.map(diet => {
                    return <Grid item xs={12} sm={6} md={4} key={diet.uid}>
                      <CustomerDietBox diet={diet} key={diet.uid}/>
                    </Grid>
                  })}

                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      variant="outlined"
                      onClick={scrollToPricing}
                      sx={{
                        border: '1px dashed grey',
                        width: '100%',
                        height: '210px',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h3>+ سفارش رژیم و برنامه تمرینی</h3>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <CustomerDietDetailsDialog fetchData={() => {
        }} dietUid={dietUid}/>

        {/*<Element name="pricing-scroll-element"/>*/}

      </div>
    </div>
  )
}

export default CustomerDietList
