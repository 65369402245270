import {alpha} from "@mui/material"
import {grey, orange} from "@mui/material/colors"
import theme from "./theme"

export const baseStyles = {
  ".sin-divider-down": {
    width: "100%",
    position: "relative",
    right: "0",
    bottom: "-8px",
  },
  ".sin-divider-up": {
    width: "100%",
    position: "relative",
    right: "0",
    top: "0",
  },
  ".sin-divider-footer": {
    width: "100%",
    position: "relative",
    right: "0",
    bottom: "-8px",
  },
  ".navbar-btn": {
    color: "white",
  },
  ".main-container": {
    // width: '100%',
    textAlign: 'center',
  },
  ".main-sub-container": {
    maxWidth: '1200px',
    display: 'inline-block',
    width: '100%',
  },
  ".hero-background": {
    position: "absolute",
    top: "50px",
    left: "0px",
    objectFit: "cover",
    width: "100%",
    height: {xs: "120%", sm: "130%", md: "100%"},
    zIndex: "-500",
    filter: "blur(0px)",
  },

  ".our-features-grid-item": {
    display: "flex",
    justifyContent: "center",
    marginBottom: 5,
  },
  ".our-features-card": {
    width: 300,
    boxShadow: '2px 10px 50px 1px rgba(0,0,0,0.20)',
  },
  '.our-features-image': {
    width: '40%',
    marginBottom: 1,
  },
  '.our-features-title': {
    marginBottom: 1,
    fontWeight: '600',
  },
  ".customer-results-grid-item": {
    display: "flex",
    justifyContent: "center",
    marginBottom: 5,
  },
  ".customer-results-card": {
    width: 300,
    boxShadow: '2px 10px 50px 1px rgba(0,0,0,0.20)',
  },
  '.customer-results-image': {
    width: '100%',
    marginBottom: 1,
  },
  '.customer-results-title': {
    marginBottom: 1,
    fontWeight: '600',
  },

  ".how-it-works": {
    background: alpha(grey[300], 0.8),
    textAlign: "center",
    color: "text.secondary",
    position: "relative",
    marginTop: "-8px",
  },
  ".how-it-works-container": {
    margin: "60px 20px 40px 20px",
    maxWidth: "800px",
    display: "inline-block",
  },
  ".how-it-works-item": {
    margin: "0 20px 0 20px",
    padding: "25px 0 25px 0",
  },
  ".how-it-works-img": {
    width: "80%",
    maxWidth: "100px",
    margin: "15px 0 0 0",
  },
  ".how-it-works-img-grid-left": {
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  ".how-it-works-img-grid-right": {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  ".how-it-works-text": {
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },

  ".footer": {
    background: alpha(grey[300], 0.8),
    textAlign: "center",
    // color: "text.secondary",
    // position: "relative",
    // marginTop: "-0px",
  },
  ".footer-container": {
    margin: "40px 0px 40px 0px",
    textAlign: "left",
    maxWidth: "1200px",
    display: "inline-block",
    color: "text.light",
  },
  ".footer-col": {
    margin: "20px 30px 20px 30px",
  },
  ".payment-card-img": {
    height: "50px",
    marginRight: "10px",
  },

  ".login": {
    textAlign: "center",
  },
  ".login-container": {
    textAlign: "left",
    display: "inline-block",
    width: "fill-available",
    maxWidth: "600px",
    margin: "10px",
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      margin: "90px 10px 50px 10px",
    },
  },
  ".login-header": {
    textAlign: "center",
    padding: "5px 0 20px 0",
  },
  ".login-content": {
    padding: "30px 0 0 0",
  },

  ".pricing": {
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
  ".pricing-container": {
    width: "100%",
    maxWidth: "1000px",
    display: "inline-block",
    padding: "60px 0 30px 0",
  },
  ".pricing-head": {
    margin: "0 20px 40px 20px",
  },
  ".pricing-box": {
    display: "inline-block",
    margin: "10px 10px 50px 10px",
    width: "300px",
    maxWidth: "90%",
  },
  ".pricing-box-discount": {
    // backgroundColor: orange[400],
    display: "inline-block",
    padding: "5px 15px 5px 15px",
    color: "text.light",
    borderRadius: "5px 5px 0 0",
  },
  ".pricing-box-header": {
    backgroundColor: alpha(theme.palette.primary.main, 0.9),
    color: "text.light",
    padding: "20px 0 0 0",
  },
  ".pricing-box-content": {
    textAlign: "left",
    padding: "20px 13% 20px 13%",
  },
  ".pricing-box-content-line": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "15px 0 15px 0",
  },
  ".pricing-box-content-line-icon": {
    color: orange[700],
    marginRight: "10px",
  },
  ".pricing-box-price": {
    textAlign: "center",
    margin: "20px 15px 20px 15px",
  },
  ".pricing-box-buy-button": {},
  ".swiper-button-prev": {
    color: theme.palette.primary[500],
    [theme.breakpoints.up("md")]: {
      left: "0",
    },
  },
  ".swiper-button-next": {
    color: theme.palette.primary[500],
    [theme.breakpoints.up("md")]: {
      right: "0",
    },
  },
  ".swiper-pagination-bullet-active": {
    background: theme.palette.primary[500],
  },
  ".display-none": {
    display: 'none !important',
  },
  ".checkout-diet": {
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
  ".checkout-diet-container": {
    width: "100%",
    maxWidth: "1000px",
    display: "inline-block",
    padding: "60px 20px 30px 20px",
  },
  ".checkout-diet-card": {
    padding: '20px',
  },
}
