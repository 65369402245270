import NavbarSidebar from "../../components/header/NavbarSidebar"
import Footer from "../../components/footer/Footer"
import CustomerDashboard from "../../components/customer_panel/dashboard/CustomerDashboard"

const CustomerDashboardPage = () => {
  return <>
    <NavbarSidebar/>
    <CustomerDashboard/>
    <Footer/>
  </>
}

export default CustomerDashboardPage
