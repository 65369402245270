import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/fonts.css'
import './styles/index.css'
import {ThemeProvider} from '@mui/material/styles'
import theme from './styles/theme'
import rtlPlugin from 'stylis-plugin-rtl'
import {CacheProvider} from '@emotion/react'
import createCache from '@emotion/cache'
import {prefixer} from 'stylis'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom"
import MainPage from "./pages/public/MainPage"
import LoginPage from "./pages/auth/LoginPage"
import App from "./App"
import {baseStyles} from "./styles/styles"
import CustomerDashboardPage from "./pages/customer_panel/CustomerDashboardPage"
import {PrivateAdminRoute, PrivateCustomerRoute} from "./components/auth/PrivateRoute"
import CustomerOrderDietPage from "./pages/customer_panel/CustomerOrderDietPage"
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3"
import {GOOGLE_RECAPTCHA_SITE_KEY} from "./data/config"
import ManualDietListAdminPage from "./pages/admin/manual-diets/ManualDietListAdminPage"
import CustomerCheckoutDietPage from "./pages/customer_panel/CustomerCheckoutDietPage";
import CustomerZarinpalCallbackPage from "./pages/customer_panel/CustomerZarinpalCallbackPage";
import VisitPage from "./pages/public/VisitPage";
import UserListAdminPage from "./pages/admin/user/UserListAdminPage";
import UserCreateUpdateAdminPage from "./pages/admin/user/UserCreateUpdateAdminPage";


const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
})


const router = createBrowserRouter(
  createRoutesFromElements(<>
    {/* public */}
    <Route index element={<MainPage/>}/>
    <Route path={'/visit'} element={<VisitPage/>}/>

    {/* auth */}
    <Route path={'/login'} element={<LoginPage/>}/>

    {/* customer panel */}
    <Route element={<PrivateCustomerRoute/>}>
      <Route path={'/dashboard'} element={<CustomerDashboardPage/>}/>
      <Route path={'/dashboard/checkout/:planUid'} element={<CustomerCheckoutDietPage/>}/>
      <Route path={'/dashboard/zarinpal/callback/:zarinReqUid'} element={<CustomerZarinpalCallbackPage/>}/>
      <Route path={'/dashboard/order-diet/:dietUid'} element={<CustomerOrderDietPage/>}/>
      <Route path={'/dashboard/customer-diets/details/:dietUid'} element={<CustomerDashboardPage/>}/>
    </Route>

    {/* admin panel */}
    <Route element={<PrivateAdminRoute/>}>
      <Route path={'/admin/manual-diets'} element={<ManualDietListAdminPage/>}/>
      <Route path={'/admin/manual-diets/details/:uid'} element={<ManualDietListAdminPage/>}/>

      <Route path={'/admin/users'} element={<UserListAdminPage/>}/>
      <Route path={'/admin/users/create'} element={<UserCreateUpdateAdminPage/>}/>
      <Route path={'/admin/users/update/:uid'} element={<UserCreateUpdateAdminPage/>}/>
    </Route>
  </>)
)


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider
          reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}
          language={'fa'}
        >
          <App>
            <RouterProvider router={router}/>
          </App>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
)
