import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {TextValidator} from "react-material-ui-form-validator";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {BACKEND_URL} from "../../data/config";
import {useGlobalContext} from "../../contexts/global_context";
import {getInputValue} from "../../utils";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


const LoginMobileForm = ({step, setStep, formValues, setFormValues}) => {
  let {sendAjax, isSendingAjax, setIsSendingAjax} = useGlobalContext()
  const {executeRecaptcha} = useGoogleReCaptcha()

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  const handleNextButton = (step, setStep) => {
    setIsSendingAjax(true)

    executeRecaptcha('LOGIN_OR_REGISTER').then(gr => {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/users/login-or-register/`,
        method: 'post',
        data: {
          ...formValues,
          gr: gr,
        },
        successMessage: '',
      }).then(r => {
        if (r.data.status_code === 200) {
          setStep('ENTER_OTP_CODE')
        }
      })
    })
  }


  return <>
    <TextValidator
      label={'شماره موبایل'}
      placeholder={'09XXXXXXXXX'}
      name={'mobile'}
      variant={'outlined'}
      autoFocus={true}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PhoneAndroidIcon color={'secondary'}/>
          </InputAdornment>
        ),
      }}
      fullWidth={true}
      validators={['required']}
      errorMessages={['This field is required']}
      onChange={handleChange}
      value={formValues.mobile}
    />

    <LoadingButton
      sx={{
        margin: '0 0 20px 0',
      }}
      color={'primary'}
      size={'large'}
      fullWidth={true}
      type={'submit'}
      variant="contained"
      endIcon={<KeyboardDoubleArrowLeftIcon/>}
      loading={isSendingAjax || !Boolean(executeRecaptcha)}
      onClick={() => {
        handleNextButton(step, setStep)
      }}
    >
      ارسال کد
    </LoadingButton>
  </>
}


export default LoginMobileForm
