import React, {Component} from 'react'
import {useContext, createContext, useState} from "react";
import {useGlobalContext} from "./global_context";
import {BACKEND_URL} from "../data/config";

let NavbarContext = createContext(null)

export const NavbarContextProvider = ({children}) => {
  let [isOpenSidebar, setIsOpenSidebar] = useState(false)
  let {sendAjax} = useGlobalContext()

  const logoutUser = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/users/logout/`,
      method: 'get',
    }).then(() => {
      window.location = '/'
    })
  }

  const toggleSidebar = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setIsOpenSidebar(open)
  }

  const value = {
    isOpenSidebar,
    setIsOpenSidebar,
    toggleSidebar,
    logoutUser,
  }

  return (
    <NavbarContext.Provider value={value}>
      {children}
    </NavbarContext.Provider>
  )
}

export const useNavbarContext = () => {
  return useContext(NavbarContext)
}
