import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import Button from "@mui/material/Button";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import SpaIcon from '@mui/icons-material/Spa';
import {Chip} from "@mui/material";
import moment from 'jalali-moment'


const CustomerDietBox = ({diet}) => {
  const [disableRipple, setDisableRipple] = useState(false)
  const navigate = useNavigate()

  const getPlanTypeDisplay = planType => {
    switch (planType) {
      case 10:
        return 'رژیم'
      case 20:
        return 'برنامه تمرینی'
      case 30:
        return 'رژیم و برنامه تمرینی'
    }
  }

  return (
    <Button
      sx={{
        padding: 2,
        textTransform: 'none',
        width: '100%',
      }}
      elevation={3}
      component={Card}
      disableRipple={disableRipple}
      onClick={(e) => {
        if (diet.status === 20) {
          navigate(`/dashboard/order-diet/${diet.uid}`)
        } else if (diet.status === 40) {
          navigate(`/dashboard/customer-diets/details/${diet.uid}`)
        }
      }}
    >
      <Grid container>
        <Grid item xs={6} sm={6} md={6} sx={{textAlign: 'left'}}>
          <SpaIcon className={'payment-card-img'}/>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'left'}}>
          <Typography variant="h5" gutterBottom>
            {getPlanTypeDisplay(diet.plan_type)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'left'}}>
          <Typography variant="subtitle1" gutterBottom>
            <Chip label={`${diet.status_display}`} color="warning" variant="outlined"/>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'left'}}>
          <Typography variant="body2" gutterBottom color={'text.secondary'}>
            زمان ثبت:
            {moment(diet.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  )
}

export default CustomerDietBox
